import React, { createContext, useContext, useState } from "react"

const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [message, setMessage] = useState("")
  const openModal = (customMessage = "") => {
    setMessage(customMessage)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setMessage("")
  }

  return (
    <ModalContext.Provider
      value={{ isModalOpen, message, openModal, closeModal }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = () => useContext(ModalContext)
