/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react"
import "./src/css/global.css"
import "react-datepicker/dist/react-datepicker.css"

import { ModalProvider } from "./src/context/modal-context"

export const wrapRootElement = ({ element }) => (
  <ModalProvider>{element}</ModalProvider>
)
